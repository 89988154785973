<template>
  <div class="card" data-aos="fade-right" data-aos-delay="200" v-if="false">
    <div class="card-body text-center">
      <h5 class="card-title mb-4">Двухфакторная аутентификация</h5>
      <div class="d-flex flex-wrap gap-2 justify-content-center">
        <div class="flex-shrink-0">
          <button class="btn btn-primary">
            <i class="ri-shield-user-line align-bottom"></i> Подключить</button>
        </div>
      </div>
    </div>
    <!-- end card body -->
  </div>
</template>

<script>
export default {
  name: "profile-2fa"
}
</script>

<style scoped>

</style>