<script>
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import flatPickr from "vue-flatpickr-component";
import ru from 'flatpickr/dist/l10n/ru.js';
import "flatpickr/dist/flatpickr.css";
import { saveSettings, changePassword, verify } from "@/helpers/home/settings";
import validate from "@/helpers/validate";
import { getPasswordResetLogs } from "@/helpers/home/logs";
import { hasPermission } from "@/helpers/permissions";
import { useToast } from "vue-toastification";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import {formatDate} from "@/helpers/timedate";
import Profile2fa from "@/components/profile/profile-2fa";

export default {
  page: {
    title: "Настройки",
    meta: [{ name: "description аккаунта", content: appConfig.description }],
  },
  components: {
    Profile2fa,
    Layout,
    flatPickr,
    PageHeader
  },
  data() {
    return {
      title: "Настройки аккаунта",
      items: [
        {
          text: "Аккаунт",
          href: "/",
        },
        {
          text: "Мой профиль",
          href: "/",
        },
        {
          text: "Настройки",
          active: true,
        },
      ],
      loading: {},
      config: {
        locale: ru.ru,
        altFormat: 'j M Y',
        dateFormat: 'd.m.Y',
      },
      mailSend: false,
      passwordResetLogs: false,
      errors: {},
      edited: {},
      toast: useToast(),
      passwords: {
        old_password: '',
        password: '',
        confirm: ''
      },
      preview: {}
    };
  },
  computed: {
    user: function() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    this.edited = Object.assign({}, this.user);
    this.edited.banner = null;
    this.edited.avatar = null;
    this.reloadPasswordResetLogs();
  },
  methods: {
    formatDate,
    hasPermission,
    save() {
      this.errors = {};
      this.errors.name = validate.name(this.edited.name);
      this.errors.email = validate.email(this.edited.email);
      this.errors.website = validate.website(this.edited.website);

      if (this.errors.name ||
          this.errors.email ||
          this.errors.website) return;

      this.loading.save = true;
      saveSettings(this.edited, {
        success: () => {
          this.$router.go(-1);
        },
        end: () => {
          this.loading.save = false;
        }
      });
    },
    verify() {
      this.errors.email = validate.email(this.edited.email);
      if (this.errors.email) return;

      this.loading.verify = true;
      verify(this.edited.email, {
        end: () => {
          this.loading.verify = false;
        },
        success: () => {
          this.mailSend = true;
        }
      });
    },
    changePassword() {
      this.errors = {};
      this.errors.old_password = validate.password(this.passwords.old_password);
      this.errors.password = validate.password(this.passwords.password);
      this.errors.confirm = validate.confirm(this.passwords.confirm, this.passwords.password);

      if (this.errors.password ||
          this.errors.old_password ||
          this.errors.confirm) return;

      this.loading.password = true;
      changePassword(this.passwords.password, this.passwords.old_password, ()=> {
        this.passwords = {};
        this.loading.password = false;
        this.reloadPasswordResetLogs();
      });
    },
    async reloadPasswordResetLogs(page = 1) {
      this.loading.passwordResetLogs = true;
      this.passwordResetLogs = await getPasswordResetLogs(page);
      this.loading.passwordResetLogs = false;
    },
    async uploadBanner(event) {
      const file = event.target.files[0];
      const img = new Image();

      try {
        img.src = URL.createObjectURL(file);
        await img.decode();
        this.errors.banner = [];
        this.errors.banner = validate.banner(img);
      } catch {
        this.errors.banner = ['Неверный формат файла'];
        return;
      }

      if (this.errors.banner) {
        return;
      }

      this.preview.banner = img.src;
      this.edited.banner = file;
    },
    async uploadAvatar(event) {
      const file = event.target.files[0];
      const img = new Image();

      if (!hasPermission('profile.change.avatar')) {
        this.toast.error('У вас нет прав на это действие!');
        return;
      }

      try {
        img.src = URL.createObjectURL(file);
        await img.decode();
        this.errors.avatar = [];
        this.errors.avatar = validate.avatar(img);
      } catch {
        this.errors.avatar = ['Неверный формат файла'];
        return;
      }

      if (this.errors.avatar) {
        return;
      }

      this.preview.avatar = img.src;
      this.edited.avatar = file;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
            :src="preview.banner || user.banner"
            class="profile-wid-img"
            alt=""
        />
        <div class="overlay-content">
          <div class="text-end p-4 pt-5" v-if="hasPermission('profile.change.banner')">
            <div v-if="edited.banner" class="p-0">
              <button class="btn btn-light ms-2" @click="edited.banner = null; preview.banner = null">
                <i class="ri-close-line align-bottom me-1"></i>
                Отмена
              </button>
            </div>
            <div v-else class="p-0 ms-auto rounded-circle profile-photo-edit">
              <input
                  id="profile-foreground-img-file-input"
                  type="file"
                  @change="uploadBanner"
                  accept=".png,.jpg"
                  class="profile-foreground-img-file-input"

              />
              <label
                  for="profile-foreground-img-file-input"
                  class="profile-photo-edit btn btn-light"
              >
                <i class="ri-image-edit-line align-bottom me-1"></i> Изменить фон
              </label>
            </div>
            <div
                v-for="(item, index) in errors.banner"
                :key="index"
                class="text-danger"
            >
              <span v-if="item"> {{ item }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5" data-aos="fade-right">
          <div class="card-body p-4">
            <div class="text-center">
              <div
                  class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                "
              >
                <img
                    :src="preview.avatar || user.avatar"
                    class="
                      rounded-circle
                      avatar-xl
                      img-thumbnail
                      user-profile-image
                    "
                    alt="user-profile-image"
                />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                      id="profile-img-file-input"
                      type="file"
                      @change="uploadAvatar"
                      accept=".png,.jpg"
                      class="profile-img-file-input"
                  />

                  <label
                      for="profile-img-file-input"
                      class="profile-photo-edit avatar-xs"
                  >
                    <span
                        class="avatar-title rounded-circle bg-light text-body"
                    >
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>

              <div
                  v-for="(item, index) in errors.avatar"
                  :key="index"
                  class="text-danger"
              >
                <span v-if="item"> {{ item }} </span>
              </div>

              <button v-if="preview.avatar"
                      class="btn d-block m-auto btn-danger mt-2 py-1"
                      @click="edited.avatar = null; preview.avatar = null">
                <i class="ri-close-line align-bottom me-1"></i>
                Отмена
              </button>

              <h5 class="fs-16 mb-1 mt-4">{{ user.login }}</h5>
              <p class="text-muted mb-0">{{ user.role_name }}</p>
            </div>
          </div>
        </div>
        <!--end card-->

        <div class="card" data-aos="fade-right" data-aos-delay="100" v-if="false">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <div class="flex-grow-1">
                <h5 class="card-title mb-0">Привязать ботов</h5>
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span
                    class="avatar-title rounded-circle fs-16 bg-dark text-light"
                >
                  <i class="bx bxl-vk"></i>
                </span>
              </div>
              <input
                  type="email"
                  class="form-control"
                  id="gitUsername"
                  placeholder="Username"
                  value="vk.com/ellidey"
              />
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-primary">
                  <i class="ri-discord-fill"></i>
                </span>
              </div>
              <input
                  type="text"
                  class="form-control"
                  id="discordInput"
                  placeholder="www.example.com"
                  value="ellidey#1323"
              />
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-success">
                  <i class="ri-telegram-fill"></i>
                </span>
              </div>
              <input
                  type="text"
                  class="form-control"
                  id="dribbleName"
                  placeholder="Username"
                  value="12354213"
              />
            </div>
          </div>
        </div>
        <!--end card-->

        <profile2fa />
      </div>
      <!--end col-->
      <div class="col-xxl-9" data-aos="fade-left" data-aos-delay="100">
        <div class="card mt-xxl-n5">
          <div v-if="loading.save" class="card-preloader d-flex flex-column align-items-center justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only"></span>
            </div>
            <p class="mt-2">Сохранение...</p>
          </div>
          <div class="card-header">
            <ul
                class="
                nav nav-tabs-custom rounded card-header-tabs border-bottom-0
              "
                role="tablist"
            >
              <li class="nav-item">
                <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#personalDetails"
                    role="tab"
                >
                  <i class="fas fa-home"></i>
                  Информация
                </a>
              </li>
              <li class="nav-item">
                <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#changePassword"
                    role="tab"
                >
                  <i class="far fa-user"></i>
                    Безопасность
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="personalDetails" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label">
                          Имя
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            id="firstnameInput"
                            placeholder="Введите ваше имя"
                            v-model="edited.name"
                            :class="{
                              'is-invalid': errors.name,
                            }"
                        />

                        <div
                            v-for="(item, index) in errors.name"
                            :key="index"
                            class="invalid-feedback"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastnameInput" class="form-label">
                          Пол
                        </label>
                        <!-- Outlined Styles -->
                        <div class="hstack gap-2 flex-wrap">
                          <input type="radio" class="btn-check" name="male" value="0" v-model="edited.gender" id="male-man">
                          <label class="btn btn-outline-primary" for="male-man">Мужской</label>

                          <input type="radio" class="btn-check" name="male" value="1" v-model="edited.gender" id="male-women">
                          <label class="btn btn-outline-primary" for="male-women">Женский</label>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="phonenumberInput" class="form-label">
                          День рождения
                        </label>
                        <flat-pickr
                            v-model="edited.birthday"
                            class="form-control"
                            :config="config"
                        ></flat-pickr>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-4">
                        <label for="emailInput" class="form-label">
                          Email адрес
                        </label>

                        <div class="input-group">
                          <input
                              type="text"
                              class="form-control"
                              id="emailInput"
                              placeholder="Введите email адрес"
                              v-model="edited.email"
                              aria-describedby="verify-btn"
                              :class="{
                                'is-invalid': errors.email,
                              }"
                          />
                          <button v-if="!user.email_verified_at || user.email !== edited.email"
                                  class="btn btn-primary"
                                  type="button"
                                  id="verify-btn"
                                  :disabled="loading.verify || mailSend"
                                  @click="verify"
                          >
                            <span v-if="loading.verify" class="spinner-border spinner-border-sm me-2" role="status">
                            </span>
                            Подтвердить
                          </button>
                          <button v-else
                                  class="btn btn-success"
                                  id="verify-btn"
                                  disabled
                          >
                            Подтверждено
                          </button>
                        </div>

                        <div
                            v-for="(item, index) in errors.email"
                            :key="index"
                            class="invalid-feedback"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="mb-4">
                        <label for="statusInput" class="form-label">
                          Статус
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            id="statusInput"
                            placeholder="Введите ваш статус"
                            v-model="edited.status"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="websiteInput" class="form-label">
                          Web-сайт
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            id="websiteInput"
                            :placeholder="'www.finaldream.net/user/' + user.login"
                            v-model="edited.website"
                            :class="{
                              'is-invalid': errors.website,
                            }"
                        />

                        <div
                            v-for="(item, index) in errors.website"
                            :key="index"
                            class="invalid-feedback"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>

                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3 pb-2">
                        <label
                            for="DescriptionControlTextarea"
                            class="form-label"
                        >
                          Обо мне
                        </label>
                        <textarea
                            class="form-control"
                            id="DescriptionControlTextarea"
                            placeholder="Введите информацию о вас..."
                            v-model="edited.about"
                            rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary" @click="save">
                          Сохранить
                        </button>
                        <v-route type="button" @click="$router.go(-1)" class="btn btn-soft-success">
                          Отмена
                        </v-route>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="oldpasswordInput" class="form-label">
                          Старый пароль
                        </label>
                        <input
                            type="password"
                            class="form-control"
                            id="oldpasswordInput"
                            placeholder="Введите старый пароль"
                            v-model="passwords.old_password"
                        />

                        <div
                            v-for="(item, index) in errors.old_password"
                            :key="index"
                            class="invalid-feedback"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label">
                          Новый пароль
                        </label>
                        <input
                            type="password"
                            class="form-control"
                            id="newpasswordInput"
                            placeholder="Введите новый пароль"
                            v-model="passwords.password"
                            :class="{
                              'is-invalid': errors.password,
                            }"
                        />

                        <div
                            v-for="(item, index) in errors.password"
                            :key="index"
                            class="invalid-feedback"
                            :class="{
                              'is-invalid': errors.password,
                            }"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label">
                          Повторите пароль
                        </label>
                        <input
                            type="password"
                            class="form-control"
                            id="confirmpasswordInput"
                            placeholder="Введите новый пароль ещё раз"
                            v-model="passwords.confirm"
                            :class="{
                              'is-invalid': errors.confirm,
                            }"
                        />

                        <div
                            v-for="(item, index) in errors.confirm"
                            :key="index"
                            class="invalid-feedback"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <router-link
                            to="/forgot"
                            class="link-primary text-decoration-underline"
                        >
                          Забыли пароль?
                        </router-link>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button
                            type="submit"
                            @click="changePassword"
                            class="btn btn-success"
                            :disabled="loading.password"
                        >
                          <span v-if="loading.password" class="spinner-border spinner-border-sm me-2" role="status"></span>
                          Изменить пароль
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <div class="mt-4 mb-3 border-bottom pb-2">
                  <h5 class="card-title">История изменений пароля</h5>
                </div>
                <div class="row">
                  <p v-if="passwordResetLogs?.data?.length < 1" class="text-center mt-2">Тут пусто...</p>
                  <div
                      v-else
                      class="d-flex align-items-center mb-4 col-lg-6"
                      v-for="(item, index) in passwordResetLogs.data"
                      :key="index"
                  >
                    <div class="flex-shrink-0 avatar-sm">
                      <div
                          class="avatar-title bg-light text-primary rounded-3 fs-18"
                      >
                        <i v-if="item.device === 0" class="ri-computer-line"></i>
                        <i v-else-if="item.device === 1" class="ri-phone-line"></i>
                        <i v-else class="ri-global-line"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h6>{{ item.browser }}</h6>
                      <p class="text-muted mb-0">
                        Изменен {{ formatDate(item.created_at) || 'Неизвестно' }} | ip: {{ item.ip_address || 'Неизвестно' }} | Локация: {{ item.location || 'Неизвестно' }}
                      </p>
                    </div>
                  </div>
                </div>

                <div v-if="passwordResetLogs?.data?.length > 0" class="d-flex justify-content-end p-3">
                  <div class="pagination-wrap hstack gap-2">
                    <button
                        class="page-item pagination-prev"
                        :disabled="passwordResetLogs.current_page === 1"
                        @click="reloadPasswordResetLogs(passwordResetLogs.current_page - 1)"
                    >
                      <i class="ri ri-arrow-left-s-line"></i>
                    </button>
                    <ul class="pagination listjs-pagination mb-0">
                      <li
                          :class="{
                          active: pageNumber === passwordResetLogs.current_page,
                        }"
                          v-for="(pageNumber, index) in passwordResetLogs.pages.slice(
                            Math.max(Math.min(passwordResetLogs.current_page - 3, passwordResetLogs.last_page - 5), 0),
                            Math.max(passwordResetLogs.current_page + 2, 5),
                        )"
                          :key="index"
                          @click="reloadPasswordResetLogs(pageNumber)"
                      >
                        <a class="page" role="button">{{ pageNumber }}</a>
                      </li>
                    </ul>
                    <button
                        class="page-item pagination-next"
                        @click="reloadPasswordResetLogs(passwordResetLogs.current_page + 1)"
                        :disabled="passwordResetLogs.current_page >= passwordResetLogs.last_page"
                    >
                      <i class="ri ri-arrow-right-s-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
