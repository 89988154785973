import axios from 'axios';
import { useToast } from "vue-toastification";
import { printError } from '@/helpers/request';
import { refresh } from '@/helpers/auth';
import store from "@/state/store";
const toast = useToast();

export function saveSettings(settings, callbacks) {
    const form_data = new FormData();

    for (let key in settings) {
        if (settings[key] !== null) {
            form_data.append(key, settings[key]);
        }
    }

    axios.post('/profile/update', form_data).then(() => {
        toast.success('Данные успешно изменены');
        refresh();
        if(callbacks?.success) callbacks.success();
        if (settings.banner) {
            const user = store.getters['auth/user'];
            store.dispatch('auth/setBanner',
                { banner: process.env.VUE_APP_URL_REST_API + '/banner/' + user.login + '?' + Date.now()});
        }
    }).catch(printError).finally(() => {
        if (callbacks?.end) callbacks.end();
    });
}

export function changePassword(password, old_password, callback = {}) {
    axios.post('/profile/update/password', {
        password,
        old_password
    }).then(() => {
        toast.success('Пароль успешно');
        refresh();
    }).catch(printError).finally(callback);
}

export function verify(email, callbacks) {
    axios.post('/profile/verify', { email }).then(() => {
        toast.success('Письмо отправлено на почтовый ящик ' + email);
        refresh();
        if (callbacks?.success) callbacks.success();
    }).catch(printError).finally(() => {
        if (callbacks?.end) callbacks.end();
    });
}