import axios from 'axios';
import { printError } from '@/helpers/request';

export async function getLoginLogs(page) {
    return await axios.get('profile/logs/login', {
        params: {
            page
        }
    }).then(({ data }) => {
        // Set pages login logs
        data.pages = [];

        for (let index = 1; index <= data.last_page; index++) {
            data.pages.push(index);
        }

        return data;
    }).catch(printError);
}

export async function getLoginSessions(page) {
    return await axios.get('profile/sessions/login', {
        params: {
            page
        }
    }).then(({ data }) => {
        // Set pages login sessions
        data.pages = [];

        for (let index = 1; index <= data.last_page; index++) {
            data.pages.push(index);
        }

        return data;
    }).catch(printError);
}

export async function deleteLoginSession(id) {
    return await axios.delete('profile/sessions/login/' + id).then(({ data }) => {
        return data;
    }).catch(printError);
}

export async function getLoginLogsByName(page, name) {
    return await axios.get('users/' + name + '/logs/login', {
        params: {
            page
        }
    }).then(({ data }) => {
        // Set pages login logs
        data.pages = [];

        for (let index = 1; index <= data.last_page; index++) {
            data.pages.push(index);
        }

        return data;
    }).catch(printError);
}

export async function getLoginSessionByName(page, name) {
    return await axios.get('users/' + name + '/sessions/login', {
        params: {
            page
        }
    }).then(({ data }) => {
        // Set pages login logs
        data.pages = [];

        for (let index = 1; index <= data.last_page; index++) {
            data.pages.push(index);
        }

        return data;
    }).catch(printError);
}

export async function getPasswordResetLogs(page) {
    return await axios.get('profile/logs/password/reset', {
        params: {
            page
        }
    }).then(({ data }) => {
        // Set pages login logs
        data.pages = [];

        for (let index = 1; index <= data.last_page; index++) {
            data.pages.push(index);
        }

        return data;
    }).catch(printError);
}

export async function getPasswordResetLogsByName(page, name) {
    return await axios.get('users/' + name  + '/logs/password', {
        params: {
            page
        }
    }).then(({ data }) => {
        // Set pages login logs
        data.pages = [];

        for (let index = 1; index <= data.last_page; index++) {
            data.pages.push(index);
        }

        return data;
    }).catch(printError);
}